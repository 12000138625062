<template>
   <v-app>
    <v-system-bar v-if="false"></v-system-bar>
    <TabAppBar title="รายการบันทึกประจำวัน"/>
    <router-view/>
     
   </v-app>
</template>

<script>
import TabAppBar from "@/components/common/TabAppBar";

export default {
    components: {
        TabAppBar,
    },
}
</script>

<style>

</style>